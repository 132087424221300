@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,900&family=Ubuntu:wght@700&display=swap');

body {
    margin: 0;
    background: #333;
    font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    overflow-x:hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header { position: fixed; padding:40px; left:0; right:0; z-index: 10; display: flex; align-items: flex-start; justify-content: space-between; }
header .logo { width:100px; cursor: pointer; }
header .logo img { width:100%; }
header .menu-btn { background: none; border: 0; padding:20px; width:68px; height:62px; margin-top:-10px; position: relative; display: flex; flex-direction: column; justify-content: space-between; cursor: pointer; }
header .menu-btn span { width:28px; height:2px; background: #fff; }

.landing {
    background: #000;
    width:100%;
    height:100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.landing.effect { max-width:660px; margin-bottom:60px; width:90%; line-height:0; margin-top:-40px; }
.landing.text { max-width:700px; width:70%; line-height:0; }

.container { width:100%; max-width:1480px; margin:0 auto; }


.bg-img { position: absolute; z-index: -1; top:0; left:0; right:0; bottom:0; display: flex; align-items: stretch; animation:blur 2s 0s ease-out backwards; }
.bg-img .left { background: url('../public/images/img-old.png') no-repeat center left; background-size:cover; flex:1; height:100%; overflow: hidden; }
.bg-img .right { background: url('../public/images/img-new.png') no-repeat center right; background-size:cover; flex:1; height:100%; overflow: hidden; }
.bg-img .screen { position:absolute; left: 0; top:0; right:0; bottom:0; background:rgba(0,0,0,0.45); animation:screenfade 3s 0s ease-out backwards; }

@keyframes blur {
    from { filter:blur(24px); transform:scale(1); }
    to { filter:blur(0); transform:scale(1); }
}

@keyframes screenfade {
    from { background:rgba(0,0,0,1); }
    to { background:rgba(0,0,0,0.45); }
}

.visual { position: relative; height: 100vh }
.visual .divider { left:50%; top:0; bottom:0; position: absolute; background: #fff; width:1px; animation:divide 2.2s 0s ease-out backwards;  }
@keyframes divide {
    from { transform: scale(0); transform-origin:top; }
    to { transform: scale(1); transform-origin:top; }
}
.float-text { position: absolute; bottom:180px; text-align: left; animation:textfade 2s 0s ease-out backwards; }
.float-text p { color:#ccc; font-weight:500; font-size:18px; letter-spacing: -0.05rem; line-height:1; padding-left:5px; }
.float-text h2 { color:#fff; font-weight:800; font-size:66px; letter-spacing: -0.2rem; margin:20px 0; line-height:1; }
.float-text span { font-weight:700; color:#397ef1; font-size:18px; letter-spacing: -0.05rem; line-height:1; padding-left:5px; }

.topper-text { position: absolute; top:170px; right:0; left:54%; text-align: left; animation:textfade 2s 0s ease-out backwards; }
.topper-text p { font-size:18px; color:#fff; line-height:1.6; letter-spacing: -0.05rem; margin:0 0 20px; }
.topper-text a { text-decoration: none; background: none; border: 0; font-weight:700; font-size:16px; color:#fff; padding:0; }
.topper-text a img { display: inline-block; cursor: pointer; margin-left:10px;transition-duration: .3s; }
.topper-text a:hover img { transform: translateX(-5px); transition-duration: .3s; }

@keyframes textfade {
    from { transform: translateY(30px); opacity: 0; }
    to { transform: translateY(0); opacity: 1;}
}

.years-text { font-family: 'Playfair Display'; position: absolute; top:48%; left:50%; transform: translate(-50%, -50%); font-weight:800; color:#fff; font-size:80px; display: flex; align-items: center; justify-content: center; }
.years-text .t1974 { overflow: hidden; flex:1; text-align: right; margin-bottom:80px; }
.years-text .t1974 span { display: block; margin-right:-10px; animation:slide1974 0.5s 1.2s ease-out backwards; }
.years-text .t2024 { overflow: hidden; flex:1; text-align: left; margin-top:80px; }
.years-text .t2024 span { display: block; margin-left:-10px; animation:slide2024 0.5s 1.2s ease-out backwards;  }

@keyframes slide1974 {
    from { transform: translateX(200px); }
    to { transform: translateX(0);}
}

@keyframes slide2024 {
    from { transform: translateX(-200px); }
    to { transform: translateX(0);}
}

.list { background: url('../public/images/bg-list.png'); height: 100vh; background-size:cover; overflow-x: hidden; }
.list.news { background: url('../public/images/bg-news.png'); height: 100vh; background-size:cover; overflow-x: hidden; }
.list .title { position: relative; text-align: left; z-index:2; display: flex; align-items: flex-start; justify-content: center; flex-direction: column; padding-top: 160px}
.list .title span { z-index: 1; position: absolute; line-height:1; left:-10px; font-size:200px; white-space: nowrap; font-weight:800; color:#fff; opacity: 0.03; }
.list .title label { color:#fff; font-size:16px; font-weight:800; }
.list .title h3 { font-size:36px; font-weight:800; color:#fff; line-height:1.2; margin:15px 0 0; }

.card-slider {
    margin-top:40px;
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    padding-left:200px;
}
.card-slider ul { margin:0 -20px; list-style: none; align-items: stretch; }
.card-slider .item-wrapper {
    display: flex;
    flex: 0 0 50%;
    flex-wrap: wrap;
    overflow: hidden;
    z-index:2;
    align-items: center;
    align-content: center;
    justify-content: center;
    max-width:660px;
    cursor: pointer;
    padding-right:60px;
}
.news .card-slider .item-wrapper {
    max-width:450px;
}
.card-slider .item {  height:100%; display: flex; flex-direction: column; width: 100%; margin: 0 auto; }
.card-slider .item p { margin:30px 0 10px; font-size:20px; line-height:1.3; letter-spacing: -0.05rem; color:#fff; font-weight:700; width:100%; text-align: left; }
.card-slider .item span { font-size:16px; display: block; color:#ccc; line-height:1; font-weight:400; width:100%; text-align: left; }
.card-slider .item .thumb { width:100%; line-height:0; border:1px rgba(255,255,255,0.1) solid; }
.card-slider .item .thumb.video { overflow: hidden; position: relative; padding-top:64.5%; height:0; background: #000; }
.card-slider .item .thumb img { width:100%; line-height:0; }
.card-slider .item .thumb video { width:100%; height:100%; object-fit:contain; position: absolute; top:0; left:0; right:0; bottom:0; }

.endline { z-index:4; cursor: pointer; transition-duration: .3s; position: absolute; right:40px; background-position-x:0; top:50%; width:70px; height:70px; border:0; background: #111; border-radius: 50%; overflow: hidden; text-indent: -9999px; background: #111 url('../public/images/arrow-m.png') no-repeat top left; background-position-y:20px;  }
.card-slider .endline:hover { transition-duration: .3s; background-position-x:-5px; }
.card-slider .item.continue { display: flex !important; justify-content: center; }
.card-slider .item.continue h4 { padding-top:90px; padding-left:30px; line-height:0.9; position: relative; letter-spacing: -0.2rem; font-family: 'Ubuntu'; text-align: left; margin:0; color:rgba(255,255,255,0.03); font-size:100px; font-weight:800; }
.card-slider .item.continue p { color:#fff; font-size:20px; position: absolute; z-index:2; bottom:0; font-family: 'Pretendard'; }

.vision-adv .back-text { position: absolute; z-index: 1; text-align: left; padding-left:5px; }
.vision-adv .back-text.start { position: absolute; z-index: 0; text-align: left; padding-left:5px; }
.vision-adv .back-text h3 { font-size:70px; line-height:1; margin-top:60px; font-family: 'Playfair Display'; font-weight:700; color:#fff; margin-bottom:0; letter-spacing: 0;  }
.vision-adv .back-text h6 { font-size:18px; font-weight:700; color:#fff; line-height:1; margin:60px 0 10px; }
.vision-adv .back-text p { font-size:18px; font-weight:400; color:#aaa; line-height:2; letter-spacing: -0.05rem; }
.vision-adv .back-text.end { text-align: right; right:0; top:0; padding-right:5px; }
.vision-adv {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    max-width:1440px;
    margin:0 auto;
}
.vision-adv ul {
    margin:0 -20px;
    list-style: none;
    align-items: stretch;
    padding-left:660px;
    padding-right:1660px;
    display: flex;
    flex-direction: row;
    z-index:2;
}
.vision-adv li { width:700px; padding-right:60px; }
.vision-adv .item-wrapper { display: flex; flex: 0 0 50%; flex-wrap: wrap;  overflow: hidden; z-index:2;align-items: center;
    align-content: center;
    justify-content: center; }
.vision-adv .item {  height:100%; display: flex; flex-direction: column; width: 100%; margin: 0 auto; }
.vision-adv .item p {
    margin:30px 0 10px;
    letter-spacing: -0.05rem;
    color:#fff;
    width:100%;
    font-size:16px;
    font-weight:500;
    line-height:1.5;
    text-align: justify;
}
.vision-adv .item p+p { margin-top:15px; }
.vision-adv .item span { font-size:16px; display: block; color:#ccc; line-height:1; font-weight:400; width:100%; text-align: left; }
.vision-adv .item .thumb { width:100%; line-height:0; border:1px rgba(255,255,255,0.1) solid; }
.vision-adv .item .thumb img { width:100%; line-height:0; }
.vision-adv .endline { z-index:4; cursor: pointer; transition-duration: .3s; position: absolute; right:40px; background-position-x:0; top:50%; width:70px; height:70px; border:0; background: #111; border-radius: 50%; overflow: hidden; text-indent: -9999px; background: #111 url('../public/images/arrow-m.png') no-repeat top left; background-position-y:20px;  }
.vision-adv .endline:hover { transition-duration: .3s; background-position-x:-5px; }

.card-slider .slick-track { padding-left:220px; }
.card-slider .slick-list { margin:0 -20px; }
.card-slider .slick-slide>div { padding:0 20px; }


.history { overflow: hidden; }
.history .flexible { display: flex; }
.history .fixed-text { padding-top:160px !important;}
.history .fixed-text .nav-list .tabs { cursor: pointer;}
.history .fixed-text h3 { font-weight:700; text-align: left; color:#fff; font-size:40px; line-height:1; margin:0; }
.history .fixed-text p { margin-top:20px; text-align: left; font-size:16px; color:#ccc; font-weight:500; line-height:1; }
.history .fixed-text ul { margin-top:30px; list-style: none; padding:0; }
.history .fixed-text li { height:100px; display: flex; align-items: center; }
.history .fixed-text .item { text-decoration: none; display: flex; flex:1; flex-direction: column; justify-content: center; align-items: stretch; }
.history .fixed-text .item p { font-size:22px; color:#999; letter-spacing: -0.1rem; font-weight:400; transition-duration: .3s; margin:10px 0 0; text-align: left; }
.history .fixed-text .item p br { display:none; }
.history .fixed-text .item label { font-size:15px; color:#999; font-weight:600; text-align: left; transition-duration: .3s;  }
.history .fixed-text li:hover p,
.history .fixed-text li:hover label,
.history .fixed-text li:focus p,
.history .fixed-text li:focus label,
.history .fixed-text li:focus-within p,
.history .fixed-text li:focus-within label,
.history .fixed-text li.active p,
.history .fixed-text li.active label { color:#fff; transition-duration: .3s; }

.history .chronicle-list {padding-top: 80vh;  padding-bottom: 42vh; flex:1; padding-left:80px; }
.history .chronicle-list ul { list-style: none; position: relative; padding:0; }
.history .chronicle-list ul:before { position: absolute; z-index: 0; background: #397ef1; width:1px; left:10px; top:8px; bottom:80px; content:'';  }
.history .chronicle-list li { height:100px; display: flex; align-items: baseline; position: relative; padding-left:40px; }
.history .chronicle-list li:before { position: absolute; left:0; z-index: 2; content:''; width:8px; height:8px; border-radius: 50%; background:#333; border:6px #397ef1 solid;  }
.history .chronicle-list li p { font-size:20px; color:#999; letter-spacing: -0.05rem; margin:0; }
.history .chronicle-list li span { font-size:16px; color:#666; margin-left:8px; }

.history .chronicle-list li.active p,
.history .chronicle-list li.active span { color:#fff; transition-duration: .3s; }
.menu {
    cursor: pointer;
}

@keyframes menu-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes menu-close {
    from { opacity: 1;}
    to { opacity: 0;}
}

.menu-screen {
    position: fixed; top:0; left:0; right:0; bottom:0; background: #fff; width:100%;
}
.menu-screen { height:100%; overflow-y: scroll; -webkit-overflow-scrolling: touch; -ms-overflow-style: none; scrollbar-width: none;  }
.menu-screen::-webkit-scrollbar {
    display: none;
}

.menu-screen.close {
    animation-name: menu-close;
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
}

.menu-screen.active {
    animation-name: menu-show;
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
}
.menu-close {
    z-index: 12;
    padding: 10px;
    width: 52px;
    height: 52px;
    position: absolute;
    right: 37px;
    top: 25px;
    cursor: pointer;
    border:0;
    background:none;
}
.menu-close span { width:32px; height:2px; background: #111; display: block; position: absolute; transform-origin: center center; top:50%; left:50%; }
.menu-close span:first-child { transform:translate(-50%, -50%) rotate(45deg); }
.menu-close span:last-child { transform:translate(-50%, -50%) rotate(-45deg); }

.menu-wrapper { padding-top:160px; }
.menu-wrapper .menu-item+.menu-item { margin-top:50px; }
.menu-wrapper .menu-item a { cursor: pointer; text-align: left; text-decoration: none; display: flex; align-items: flex-start; flex-direction: column; }
.menu-wrapper .menu-item h4 { position: relative; font-size:40px; line-height:1; margin:0; color:#999; font-weight:800; letter-spacing: -0.1rem; transition-duration: .3s; }
.menu-wrapper .menu-item span { display: block; font-size:16px; color:#aaa; font-weight:500; letter-spacing: -0.05rem; text-decoration: none; margin-top:10px; padding-left:3px; transition-duration: .3s; }
/*.menu-wrapper .menu-item h4:before { content:''; height:6px; top:calc(50% - 3px); width:100%; position: absolute; z-index: 2; background: #1053dd; transform: scaleX(0); transform-origin: left; transition-duration: .5s;  }*/

.menu-wrapper .menu-item:focus h4,
.menu-wrapper .menu-item:focus span,
.menu-wrapper .menu-item:focus-within h4,
.menu-wrapper .menu-item:focus-within span,
.menu-wrapper .menu-item:hover h4,
.menu-wrapper .menu-item:hover span,
.menu-wrapper .menu-item .active h4,
.menu-wrapper .menu-item .active span { color:#111; transition-duration: .3s; }
/*.menu-wrapper .menu-item:hover h4:before,*/
/*.menu-wrapper .menu-item .active h4:before { transform: scale(1); transition-duration: .5s; }*/
.menu-wrapper .divide { height:1px; width:100%; background: #e2e4e7; margin:40px 0; }
.menu-wrapper .category { text-align: left; margin-bottom:20px; font-weight:800; font-size:16px; color:#123d97; }

.modal-wrapper { top:0; left:0; right:0; bottom:0; background: rgba(0,0,0,0.7); }
.modal-wrapper .case { position: absolute;left:0; right:0; top:50%; transform: translateY(-50%); }
.img-thumb { max-width:700px; width:100%; display: block; margin:0 auto; position: relative; }
.modal-wrapper .img-thumb img { width:100%; }
.modal-wrapper .close { z-index: 1; cursor: pointer; position: absolute; display: flex; align-items: center; justify-content:center; top:20px; right:20px; border-radius: 50%; background:#111; width:60px; height:60px;  }
.modal-wrapper .close span { width:34px; height:1px; background: #459bf1; position: absolute; transform:rotate(45deg); }
.modal-wrapper .close span:last-child { transform: rotate(-45deg); }
.modal-wrapper .prev-arr {
    z-index:1;
    cursor: pointer;
    transition-duration: .3s;
    position: absolute;
    left:20px;
    /*background-position-x:0;*/
    top:50%;
    width:60px;
    height:60px;
    border:0;
    background: #111;
    border-radius: 50%;
    overflow: hidden;
    background: #111 url('../public/images/arrow-prev-m.png') no-repeat top right;
    background-size:42px;
    text-indent: -9999px;
    background-position-y:16px;
}
.modal-wrapper .next-arr {
    z-index:1;
    cursor: pointer;
    transition-duration: .3s;
    position: absolute;
    right:20px;
    background-position-x:0;
    top:50%;
    width:60px;
    height:60px;
    border:0;
    background: #111;
    border-radius: 50%;
    overflow: hidden;
    text-indent: -9999px;
    background: #111 url('../public/images/arrow-m.png') no-repeat top left;
    background-size:42px;
    background-position-y:16px;
}
.text-modal .close {
    top:-20px;
    right:-20px;
}
.text-modal {
    width:80%;
    max-width:380px;
    background: #fafafa;
    border:3px #397ef1 solid;
    box-shadow: 0 5px 40px rgba(0,0,0,0.2);
    height:240px;
    border-radius: 20px;
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.text-modal p {
    text-align: center;
    font-size:28px;
    font-weight:700;
    width:100%;
    margin:0 0 15px;
    color:#999;
}
.text-modal span {
    font-size:16px;
    color:#aaa;
}

.detail-page {
    background: #fafafa;
    min-height:100vh;
}

.detail-container {
    max-width:1280px;
    width:100%;
    margin:0 auto;
    padding-top:160px;
    padding-bottom:100px;
}

.detail-page .title {
    border-bottom:2px #111 solid;
    padding-bottom:40px;
    margin-bottom:40px;
}
.detail-page h2 {
    font-size:34px;
    font-weight:800;
    color:#111;
    margin:0 0 10px;
    letter-spacing: -0.1rem;
    line-height:1;
}
.detail-page p {
    color:#777;
    margin:0;
    font-size:18px;
    font-weight:400;
    letter-spacing: -0.08rem;
    line-height:1;
}
.detail-page .divider {
    height:1px;
    background:rgba(255,255,255,0.2);
    margin:40px 0;
}
.detail-page .contents .blue {
    border-radius: 30px;
    overflow: hidden;
    background: #2463d8 url("/public/images/bg-blue-hl.png") no-repeat top center;
    background-size:100%;
    padding:0 80px 80px;
}
.detail-page .contents .dark {
    border-radius: 30px;
    overflow: hidden;
    background: #242528 url("/public/images/bg-dark-hl.png") no-repeat top center;
    background-size:100%;
    padding:80px;
}
.detail-page .contents .thumbnails {
    border-radius: 30px;
    overflow: hidden;
    background: #367ade;
    padding:0px;
}

.detail-page .contents .thumbnails.cart {
    background: #e4f3f9;
}

.detail-page .contents .thumbnails.cart h4 {
    font-size:22px;
    color:#1c94db;
    line-height:1.5;
    margin:20px 0;
    text-align: center;
}


.detail-page .contents .thumbnails .img {
    width:100%;
    line-height:0;
}
.detail-page .contents .thumbnails .img img {
    width:100%;
}
.detail-page .contents .thumbnails .img .pc {
    display:block;
}
.detail-page .contents .thumbnails .img .mobile {
    display:none;
}
.detail-page .contents .thumbnails .inner-wrapper {
    padding:0px 80px 80px;
}
.detail-page .contents .thumbnails .inner-wrapper p {
    color:#fff;
}

.detail-page .contents .dark .thumb { margin:40px auto; text-align: center; }
.detail-page .contents .dark .thumb img { width:100%; max-width:1120px; }


.detail-page .contents .item {
    padding:80px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom:1px #38393c solid;
}
.detail-page .contents .item.rev {
    flex-direction: row-reverse;
}
.detail-page .contents .item.rev .item-contents {
    padding-left:0;
    padding-right:40px;
}

.detail-page .contents .item.full {
    flex-direction: column;
    align-items: stretch;
}
.detail-page .contents .item .item-thumb {
    flex:1;
    max-width:50%;
}
.detail-page .contents .item.full .item-thumb {
    max-width:100%;
}
.detail-page .contents .item .item-thumb img {
    flex:1;
    width:100%;
}

.detail-page .contents .item .item-contents {
    flex:1;
    padding-left:40px;
}
.detail-page .contents .item.full .item-contents {
    padding-left:0px;
    margin-bottom:40px;
}
.detail-page .contents .item .item-contents h5,
.detail-page .contents .item .item-contents h4,
.detail-page .contents .item .item-contents p {
    text-align: left;
    padding:0;
    margin:0;
    max-width:none;
}

.detail-page .contents .item .item-contents h5 {
    font-weight:900;
    font-size:24px;
    color:#2a7efb;
    margin-top:0;
    margin-bottom:5px;
}
.detail-page .contents .item .item-contents h4 {
    font-weight:700;
    font-size:30px;
    margin-top:0;
    margin-bottom:15px;
}
.detail-page .contents .item .item-contents p {
    line-height:1.7;
    font-size:16px;
    letter-spacing: -0.06rem;
}
.detail-page .contents .item .item-contents span {
    line-height:1.7;
    font-size:14px;
    letter-spacing: -0.03rem;
    color:#fff;
}

.detail-page .contents .tail {
    margin-top:80px;
    font-size:18px;
    font-weight:500;
    line-height:1.7;
    letter-spacing: -0.05rem;
}

.detail-page .contents h4 {
    margin:0 0 20px;
    font-size:18px;
    color:#fff;
    font-weight:600;
    text-align: left;
    line-height:1.5;
}

.detail-page .contents .dark h4,
.detail-page .contents .dark p {
    color:#fff;
}
.detail-page .contents p {
    font-size:16px;
    color:#d5dded;
    font-weight:400;
    text-align: left;
    line-height:1.6;
    letter-spacing: 0;
    margin:0 auto;
    word-break: keep-all;
}
.detail-page .contents p a {
    font-weight:700;
    text-decoration: none;
    color:inherit;
}
.detail-page .contents p+p {
    margin-top:15px;
}

.detail-page .video {
    max-width:500px;
    width:100%;
    margin:80px auto 80px;
    position: relative;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
    line-height:0;
}
.detail-page .dark .video {
    max-width:1024px;
}
.detail-page .video:after {
    content:'';
    background: url("/public/images/shadow-contents.png") no-repeat;
    background-size:100%;
    left:-30px;
    right:-30px;
    height:16px;
    bottom:-16px;
    position: absolute;
}
@media screen and (max-width:1440px) {
    header .logo { width:80px; }
    .menu-wrapper { padding-left:40px; padding-right:40px; }
    .container { width:auto; margin:0 80px; }
    .topper-text p,
    .topper-text a,
    .float-text p { font-size:16px; }
    .float-text h2 { font-size:50px; }
    .float-text span { font-size:16px; }
    .card-slider { padding-left:80px; }
    .card-slider .slick-track { padding-left:80px; }
    .card-slider .endline { top:30%; }
    .list .title h3 { font-size:28px; }
    .list .title label { font-size:14px; }
    .list .title span { font-size:150px; }
    .card-slider .item.continue h4 { padding-top:0; }
    .card-slider .item p { margin-top:20px; font-size:18px; }

    .vision-adv { margin:0 80px; width:auto; }
    .vision-adv .back-text h3 { font-size:60px; }
    .vision-adv ul { padding-left:540px; padding-right:1280px; }
    .vision-adv li { width:600px; }




}
@media screen and (max-width:1280px) {
    .detail-container {
        margin:0 40px;
        width:auto;
    }
    .detail-header {
        background: #fafafa;
        padding:20px 30px;
    }
    .detail-header .menu-close {
        padding:0;
    }
    .detail-page .title {
        margin-bottom:30px;
        padding-bottom:30px;
        border-width:1px;
    }
    .detail-page h2 {
        font-size:30px;
    }
    .detail-page p {
        font-size:16px;
    }
    .detail-page .video {
        margin-bottom:60px;
    }
    .detail-page .contents h4 {
        margin:0 auto 20px;
        word-break: keep-all;
    }
}


@media screen and (max-width:1024px) {
    .topper-text { padding-top:20px; }
    .topper-text p { margin-bottom:10px; }
    .topper-text a img { width:28px; }

    .topper-text p,
    .topper-text a,
    .float-text p { font-size:14px; }
    .vision-adv ul { padding-right:1720px; }
    .vision-adv .back-text h3 { font-size:50px; margin-top:40px; }
    .vision-adv .back-text h6 { font-size:16px; margin-top:30px; }
    .vision-adv .back-text p { font-size:16px; }
    .vision-adv .item p { font-size:16px; font-weight:400; }
    .vision-adv li { width:540px; }

    .history .fixed-text { max-width:300px !important; }
    .history .fixed-text h3 { font-size:30px; }
    .history .fixed-text p { font-size:14px; margin:15px 0 0;}
    .history .fixed-text ul {  }
    .history .fixed-text li { height:auto; margin-bottom:30px; }
    .history .fixed-text .item p { font-size:18px; line-height:1.4; margin-top:5px; letter-spacing: -0.03rem; word-break: keep-all; }

    .history .chronicle-list li { padding-left:30px; flex-direction: column; height:80px; }
    .history .chronicle-list li:before { width:6px; height:6px; border-width:5px; top:2px; }
    .history .chronicle-list li p { font-size:18px; }
    .history .chronicle-list li span { font-size:14px; display: block; margin-top:8px; }
    .history .chronicle-list ul:before { left:8px; }
    .history .chronicle-list { padding-bottom:38vh; }

    .detail-page .contents .item .item-contents h5 { font-size:18px; }
    .detail-page .contents .item .item-contents h4 { font-size:22px; }
    .detail-page .contents .item .item-contents p { font-size:15px; letter-spacing: -0.01rem; }
    .detail-page .contents .item .item-contents p br { display: none; }

    .detail-page .contents .thumbnails .img .pc {
        display:none;
    }
    .detail-page .contents .thumbnails .img .mobile {
        display:block;
    }

    .detail-page .contents .thumbnails.cart h4 {
        font-size:20px;
        margin:20px 40px;
    }

}
@media screen and (max-width:768px) {
    .menu-wrapper { padding-left:0; padding-right:0; }
    .menu-wrapper .menu-item h4 { font-size:40px; }
    .menu-wrapper .menu-item+.menu-item { margin-top:40px; }
    .container { margin:0 40px; }
    .float-text { bottom:80px; }
    .card-slider .slick-track { padding-left:40px; }
    .visual .divider { width:100%; height:1px; left:0; top:50%; animation:dividem 2s 0s ease-out backwards;  }
    @keyframes dividem {
        from { transform: scale(0); transform-origin:center; }
        to { transform: scale(1); transform-origin:center; }
    }
    .topper-text { left:40px; }
    .bg-img { flex-direction: column; }
    .years-text { top:48.5%; flex-direction: column; }
    .years-text .t1974 { margin-bottom:0; }
    .years-text .t2024 { margin-top:-10px; }
    .years-text .t1974 span { margin-right:0; margin-bottom:-10px; animation:slide1974m 0.5s 1.2s ease-out backwards; }
    .years-text .t2024 span { margin-left:0; margin-top:-10px; animation:slide2024m 0.5s 1.2s ease-out backwards; }

    @keyframes slide1974m {
        from { transform: translateY(200px); }
        to { transform: translateY(0);}
    }

    @keyframes slide2024m {
        from { transform: translateY(-200px); }
        to { transform: translateY(0);}
    }

    .vision-adv ul { padding-right:1980px; }
    .fixed-text { padding-top:0; }
    .history .chronicle-list { padding-left:60px; }
    .detail-page .contents .thumbnails .inner-wrapper,
    .detail-page .contents .blue { padding:0 40px 60px; }
    .detail-page .contents .dark { padding:60px 40px; }
    .detail-page .contents .item { padding:40px 0; }
    .detail-page .contents .item,
    .detail-page .contents .item.rev {
        flex-direction: column-reverse;
    }
    .detail-page .contents .item .item-contents,
    .detail-page .contents .item.rev .item-contents {
        margin-bottom:40px;
        padding:0;
    }
    .detail-page .contents .item .item-thumb,
    .detail-page .contents .item.rev .item-thumb {
        max-width:none;
        width:100%;
    }
    .detail-page .contents .tail {
        font-size:16px;
        margin-top:40px;
        letter-spacing: -0.02rem;
    }
    .detail-page .contents .tail br {
    display: none;}

}
@media screen and (max-width:475px) {


    .detail-page .contents .thumbnails.cart h4 {
        font-size:11px;
        margin:20px;
    }

    .detail-container {
        margin:0;
        width:auto;
        padding-top:100px;
        padding-bottom:0;
    }
    .detail-page .title {
        padding:0 30px 20px;
        margin-bottom:0;
        border-color:#eee;
    }
    .detail-page .contents .thumbnails { border-radius: 0; }
    .detail-page .contents .thumbnails .inner-wrapper,
    .detail-page .contents .blue {
        border-radius: 0;
        padding:0 30px 80px;
    }
    .detail-page .contents .dark {
        border-radius: 0;
        padding:40px 20px 80px;
    }
    .detail-page h2 {
        font-size:24px;
        letter-spacing: -0.06rem;
    }
    .detail-page p {
        font-size:14px;
        letter-spacing: -0.05rem;
    }
    .detail-page .contents .thumbnails .inner-wrapper p br {
        display: none;
    }
    .detail-page .video {
        margin-bottom:30px;
        margin-top:30px;
    }
    .detail-page .contents h4 {
        max-width:100%;
        margin:0 0 20px;
        font-size:18px;
        word-break: keep-all;
    }
    .detail-page .contents p {
        max-width:100%;
        margin:0;
        font-size:14px;
    }
    .modal-wrapper .next-arr,
    .modal-wrapper .prev-arr,
    .modal-wrapper .close { width:40px; height:40px; }
    .modal-wrapper .next-arr,
    .modal-wrapper .prev-arr { background-size:28px; background-position-y: 14px }
    .modal-wrapper .close span { width:24px; }


    header .menu-btn { margin-right:-15px; }
    header { padding:30px; }
    .menu-close { right:12px; top:16px; }
    header .logo { width:50px; }
    .menu-wrapper { padding-left:0px; padding-right:0px; padding-top:100px; }
    .menu-wrapper .divide { margin:30px 0; }
    .menu-wrapper .menu-item h4 { font-size:22px; letter-spacing: -0.05rem; }
    .menu-wrapper .menu-item span { font-size:14px; }
    .menu-wrapper .menu-item+.menu-item { margin-top:30px; }
    .menu-wrapper .category { font-size:14px; }
    .list .title span { font-size:100px; }
    .list .title h3 { font-size:18px; margin-top:10px; }
    .card-slider { padding-left:30px; }
    .card-slider .item p { margin-top:15px; font-size:16px; }
    .card-slider .item span { font-size:13px; }
    .card-slider .endline { width:40px; height:40px; top:23%; background-size:70%; background-position-y: 13px }
    .card-slider .item-wrapper { padding-right:30px; min-width:78vw; }
    .news .card-slider .item-wrapper { min-width:66vw; }
    .card-slider .slick-list { margin:0; }
    .card-slider .slick-slide>div { padding:0; }
    .card-slider .slick-track { padding-left:30px; }

    .years-text { top:49%; font-size:40px; }
    .years-text .t1974 span { margin-bottom:0; }
    .years-text .t2024 span { margin-top:0; }

    .topper-text { left:30px; top:100px; padding-top:15px; }
    .topper-text p  { padding-right:30px; text-align: justify; }

    .container { margin:0 30px; }
    .float-text h2 { font-size:40px; }
    .card-slider .item.continue h4 { padding-left:0; font-size:70px; }
    .card-slider .item.continue p { font-size:14px; letter-spacing: 0; }


    .endline { width:40px; height:40px; background-size:70%; background-position-y:12px; }
    .vision-adv { margin:0 30px; }
    .vision-adv li { min-width:80vw; padding-right:30px; }
    .vision-adv ul { padding-right:1960px; }

    .vision-adv .back-text h6 { font-size:14px; margin-top:20px; }
    .vision-adv .back-text p { font-size:14px; text-align: justify; }
    .vision-adv .back-text p br { display: none; }
    .vision-adv .back-text h3 {
        font-size:30px;
        line-height:1.1;
        margin-top:15px;
    }
    .section.vision { padding-top:120px; }
    .card-slider .item p { font-size:14px; }
    .history { padding-top:0 !important; }
    .history .flexible { flex-direction: column; }
    .history .fixed-text { padding-top:100px !important; border-bottom:1px #444 solid; position: relative; left:-30px !important; right:0 !important;max-width:calc(100vw + 30px) !important; z-index:4; padding-left:60px; width:calc(100vw + 30px) !important; background: #333; }
    .history .fixed-text h3 { font-size:22px; }
    .history .fixed-text p { margin-top:8px; }
    .history .fixed-text ul { height:60px; max-height:80px; overflow: hidden; margin-top:15px; }
    .history .fixed-text li { margin-bottom:0; height:0px; overflow: hidden; }
    .history .fixed-text li.active { height:60px; }
    .history .fixed-text .item { }
    .history .fixed-text .item label { font-size:13px; }
    .history .fixed-text .item p { font-size:15px; }
    .history .chronicle-list { padding-top:20vh; padding-left:0; padding-bottom:40vh;}
    .history .chronicle-list li p { font-size:16px; }
    .history .chronicle-list li span { margin-left:0px; }
    .history .fixed-text .item p br { display: block; }

    .history .chronicle-list ul:before { bottom:70px; }

}

/*.history {*/
/*    padding-top: 100%;*/
/*}*/

.vision {
    width: 100%;
    height: 300vh;
    position: relative;
    padding: 0;
    overflow-x: hidden;
}

.vision.container {
    margin-top: 160px;
}

.back-text .end {
    opacity: 0;
}

.list-ui {
    width: 200vw;
}

.fp-watermark { display: none; }
.fake-youtube-img {
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
    transition-duration: .5s;
    position: absolute;
}

.fake-youtube-img.active{
    cursor: pointer;
    opacity: 1;
    transition-duration: .5s;
    z-index: 2;

}

.modal-wrapper {
    position: fixed;
    z-index: 10;
    top: 0;

}

.policy {
    max-width: 1024px;
    margin: 0 auto;
    padding: 20px;
}
.policy h2 {
    font-size:18px;
    font-weight:800;
    color:#fff;
    margin:30px 0;
}
.policy h4 {
    font-size:16px;
    font-weight:700;
    color:#fff;
    margin:0 0 20px;
}
.policy h5 {
    font-size:14px;
    color:#fff;
    margin:0 0 10px;
}
.policy div {
    margin-bottom:30px;
}
.policy p {
    font-size:14px;
    color:#eee;
    line-height:1.6;
}
.policy ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.policy li {
    font-size:14px;
    color:#eee;
    line-height:1.6;
    margin-bottom:8px;
}
.policy li > ul {
    margin-top:5px;
    padding-left:15px;
}
.policy table {
    width:100%;
    margin:10px 0;
}
.policy th {
    border-bottom:1px #444 solid;
    padding:15px 5px;
    color:#fff;
    font-size:14px;
    text-align: left;
    font-weight:600;
    line-height:1.4;
    word-break: keep-all;
}
.policy td {
    border-bottom:1px #444 solid;
    word-break: keep-all;
    padding:15px 5px;
    vertical-align: top;
    color:#eee;
    font-size:14px;
    line-height:1.7;
    text-align: left;
}

video[poster]{
    height:100%;
    width:100%;
}
